const config : Config = {
  privateKey: '$sywuwkiihfcVWo%6cSpJdb9Ghm3i@ri#B*4BzjGSYK6waRP@sDvFY3dGFfG9ET^YZXU777XJV4r',
  backendUrl: '/api/macros/s/AKfycbxIJTvU-sgoY0SkdlcUulR3E85BAKpXZf-zyiDJAQoj9pExk81DbANoab-ZFZac30Ia/exec',
  recaptchaKey: '6LeOWBEeAAAAAMHp9UsE1ueqIn_0IFuxmxQrTBpD'
}

interface Config {
  privateKey: string,
  backendUrl: string,
  recaptchaKey: string
}

export default config;