import * as React from "react";
import {
  MDBCard, MDBCardBody,
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselElement,
  MDBCarouselInner,
  MDBCarouselItem
} from "mdb-react-ui-kit";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import "./logosComponent.scss"
import getReferences from "../lib/getReferences";
import {MDXRenderer} from "gatsby-plugin-mdx";
import "./referencesCarousel.scss";

const ReferencesCarousel = () => {
  const references = getReferences().allFile.nodes;

  return (
    <MDBCarousel showIndicators showControls fade className="references-carousel" interval={100000}>
      <MDBCarouselInner>
        {
          references.map((reference, index) => (
            <MDBCarouselItem className={index == 0 ? "active": ""} key={reference.id}>
              <MDBCard>
                <MDBCardBody className="py-4 py-md-5">
                  <GatsbyImage alt={reference.childMdx.frontmatter.profileImageAlt}
                               image={getImage(reference.childMdx.frontmatter.profileImage)!} />
                  <MDBCarouselCaption className="text-black d-block px-2">
                    <MDXRenderer>{reference.childMdx.body}</MDXRenderer>
                    <span className="author">
                      <span className="name">{reference.childMdx.frontmatter.name}</span>
                    </span>
                  </MDBCarouselCaption>
                </MDBCardBody>
              </MDBCard>
            </MDBCarouselItem>
          ))
        }
      </MDBCarouselInner>
    </MDBCarousel>
  )
}

export default ReferencesCarousel
