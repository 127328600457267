import {graphql, useStaticQuery} from "gatsby";

export interface GlobalContent {
  contactButton: string,
  siteTitle: string,
  phoneNumber: string,
  email: string,
  location: string,
  footerHeading: string,
  footerParagraph: string,
  footerContactHeading: string,
  domainName: string,
  formName: string,
  formEmail: string,
  formPhone: string,
  formMessage: string,
  formSubmit: string,
  formRequired: string,
  formInvalidEmail: string,
  formConfirmation: string,
  formFailure: string,
  formRecaptcha: string,
  notFoundHeadline: string,
  notFoundText: string,
  notFoundButton: string,
  notFoundLink: string,
  homepageSEODescription: string
}

export interface GlobalContentQuery {
  file: {
   childMdx: {
     frontmatter: GlobalContent
   }
  }
}

export const graphqlGlobalContentQuery = graphql`
  query {
    file(sourceInstanceName: {eq: "content"}, name: {eq: "global"}) {
      childMdx {
        frontmatter {
          contactButton
          siteTitle
          phoneNumber
          email
          location
          footerHeading
          footerParagraph
          footerContactHeading
          domainName
          formName
          formEmail
          formPhone
          formMessage
          formSubmit
          formRequired
          formInvalidEmail
          formConfirmation
          formFailure
          formRecaptcha
          notFoundHeadline
          notFoundText
          notFoundButton
          notFoundLink
          homepageSEODescription
        }
      }
    }
  }`;

export default function getGlobalContent() : GlobalContentQuery {
  return useStaticQuery(graphqlGlobalContentQuery);
}