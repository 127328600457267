import {graphql, useStaticQuery} from "gatsby";
import {ImageDataLike} from "gatsby-plugin-image";

export interface LogosQuery {
  allFile: {
    nodes: [{
      id: string,
      base: string,
      childImageSharp: {
        gatsbyImageData: ImageDataLike
      }
    }]
  }
}

export default function getAllLogos() : LogosQuery {
  return useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "logos"}}) {
        nodes {
          id
          base
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);
}