import * as React from "react";
import {Formik, Form, ErrorMessage} from 'formik';
import {MDBBtn, MDBCol, MDBInput, MDBRow, MDBSpinner} from "mdb-react-ui-kit";
import "./contactFormComponent.scss";
import getGlobalContent, {GlobalContent} from "../lib/getAllContent";
import {object, string } from "yup";
import md5 from "md5";
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha";

interface ContactFormValues {
  name: string,
  email: string,
  phone: string,
  message: string,
  token: string,
  sentAt: string,
  recaptcha: string
}

interface ContactFormProps {
  texts: GlobalContent
}

interface ContactFormState {
  submitted?: boolean,
  submitInProgress: boolean
}

const ContactFormComponent = () => {
  const texts = getGlobalContent().file.childMdx.frontmatter;

  return (
    <ContactFormComponentInternal texts={texts} />
  )
};

export default ContactFormComponent;

class ContactFormComponentInternal extends React.Component<ContactFormProps, ContactFormState> {

  constructor(props: ContactFormProps) {
    super(props);
    this.state = {submitted: undefined, submitInProgress: false};
  }
  readonly initialValues : ContactFormValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
    token: '',
    sentAt: '',
    recaptcha: ''
  }

  readonly schema = object({
    name: string().required(this.props.texts.formRequired),
    email: string().required(this.props.texts.formRequired).email(this.props.texts.formInvalidEmail),
    recaptcha: string().required(this.props.texts.formRecaptcha)
  })
  //
  // componentDidMount() {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://www.google.com/recaptcha/api.js";
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);
  // }

  protected onSubmit(values : ContactFormValues, resetForm: any) {
    this.setState({submitInProgress: true});
    values.sentAt = (new Date()).toISOString();
    values.token = md5(`${values.sentAt}:${config.privateKey}`);

    typeof window !== "undefined" && window.gtag("event", "form-button-click", {"anec":"form"});

    fetch(
      config.backendUrl,
      {
        method: 'POST',
        redirect: 'follow',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      }
    )
      .then((response) => {
        if(response.ok) {
          return this.formSubmitted(values, resetForm);
        } else {
          console.log(response.statusText);
          this.setState({submitted: false, submitInProgress: false});
        }
      })
      .catch((reason) => {
        console.log(reason);
        this.setState({submitted: false, submitInProgress: false});
      });
  }

  public formSubmitted(values: ContactFormValues, resetForm: any) {
    this.setState({submitted: true, submitInProgress: false});
    // @ts-ignore
    typeof window !== "undefined" && window.gtag("event", "form-submit", {"anec":"form","submittedAt": values.sentAt});
  }

  public render() {
    const texts = this.props.texts;

    return (
      <Formik initialValues={this.initialValues}
              onSubmit={(values, actions) => {this.onSubmit(values, actions.resetForm)}}
              validationSchema={this.schema}>
        {
          ({values, touched,handleChange, handleBlur, setFieldValue}) =>
            (
              <Form className="contact-form p-3 p-mb-5">
                {
                  this.state.submitted === true ? (
                    <div className="alert alert-success" role="alert" data-mdb-color="success">
                    {texts.formConfirmation}
                    </div>
                  ) : <React.Fragment />
                }
                {
                  this.state.submitted === false ? (
                    <div className="alert alert-danger" role="alert" data-mdb-color="danger">
                      {texts.formFailure}&nbsp;<a href={"mailto:" + texts.email}>{texts.email}</a>
                    </div>
                  ) : <React.Fragment />
                }
                <MDBRow>
                  <MDBCol size="12" md="4">
                    <MDBInput type="text"
                              id="name"
                              label={texts.formName}
                              onChange={handleChange}
                              onInput={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              readonly={this.state.submitted} />
                    <ErrorMessage name="name" component="span" className="invalid-feedback" />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <MDBInput type="email"
                              id="email"
                              label={texts.formEmail}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              readonly={this.state.submitted} />
                    <ErrorMessage name="email" component="span" className="invalid-feedback" />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <MDBInput type="tel"
                              id="phone"
                              label={texts.formPhone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              readonly={this.state.submitted} />
                    <ErrorMessage name="phone" component="span" className="invalid-feedback" />
                  </MDBCol>
                  <MDBCol size="12">
                    <MDBInput textarea
                              id="message"
                              label={texts.formMessage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                              rows={5}
                              readonly={this.state.submitted} />
                    <ErrorMessage name="message" component="div" className="invalid-feedback" />
                  </MDBCol>
                  <MDBCol size="12" className={values.name && values.email && !this.state.submitted ? undefined : 'd-none'}>
                    <div className="recaptcha">
                      <ReCAPTCHA sitekey={config.recaptchaKey}
                                 onChange={(value) => setFieldValue('recaptcha', value)}
                                 hl="en"/>
                      <ErrorMessage name="recaptcha" component="div" className="invalid-feedback" />
                    </div>
                  </MDBCol>
                  <MDBCol size="12">
                    <MDBBtn color="primary" type="submit" size="lg" disabled={this.state.submitted === true}>{texts.formSubmit}</MDBBtn>
                    {
                      this.state.submitInProgress ? (
                        <MDBSpinner color='primary'>
                          <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                      ) : <React.Fragment />
                    }
                  </MDBCol>
                </MDBRow>
              </Form>
            )
        }
      </Formik>
    );
  }
}