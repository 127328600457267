import * as React from "react";
import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import getAllLogos from "../lib/getLogosImages";
import {GatsbyImage, getImage, ImageDataLike} from "gatsby-plugin-image";
import "./logosComponent.scss"
import {ComponentProps} from "react";
import {Link} from "gatsby";

interface PartnerLogo {
  img: string,
  link: string,
  alt: string
}

interface LogosComponentProps extends ComponentProps<any> {
  logos: PartnerLogo[]
}

const LogosComponent = (props: LogosComponentProps) => {
  const allLogos = getAllLogos();

  return (
    <MDBContainer className="logos-component">
      {
        props.logos.reduce((all,one,i) => {
          const ch = Math.floor(i/5);
          // @ts-ignore
          all[ch] = [].concat((all[ch]||[]),one);
          return all
        }, []).map((logosGroup: PartnerLogo[], index) => (
          <MDBRow className="justify-content-center" key={index}>
            {
              logosGroup.map((mdxLogo) => {
                let logoImage = allLogos.allFile.nodes.find((imageLogo) => imageLogo.base === mdxLogo.img);
                let imageData = getImage(logoImage!.childImageSharp.gatsbyImageData);
                if(imageData)
                  imageData.backgroundColor = '$fff';
                return logoImage ? (
                  <MDBCol size="4" md="2" className="py-2 py-md-3 px-2 px-md-4" key={logoImage.id}>
                    <a href={mdxLogo.link} target="_blank" className="d-flex align-items-center">
                      <GatsbyImage alt={mdxLogo.alt} image={imageData!} key={logoImage.id} title={mdxLogo.alt}/>
                    </a>
                  </MDBCol>
                ) : <></>
              })
            }
          </MDBRow>
        ))
      }
      {/*<MDBRow className="justify-content-center">*/}
      {/*{*/}
      {/*  props.logos.map((mdxLogo, index) => {*/}
      {/*    let logoImage = allLogos.allFile.nodes.find((imageLogo) => imageLogo.base === mdxLogo.img);*/}
      {/*    return logoImage ? (*/}
      {/*      <MDBCol size="4" md="2" className="py-2 px-4" key={logoImage.id}>*/}
      {/*        <a href={mdxLogo.link} target="_blank" className="d-flex align-items-center">*/}
      {/*          <GatsbyImage alt={mdxLogo.alt} image={getImage(logoImage.childImageSharp.gatsbyImageData)!} key={logoImage.id} title={mdxLogo.alt}/>*/}
      {/*        </a>*/}
      {/*      </MDBCol>*/}
      {/*    ) : <></>*/}
      {/*  })*/}
      {/*}*/}
      {/*</MDBRow>*/}
    </MDBContainer>
  )
}

export default LogosComponent

