import {graphql, useStaticQuery} from "gatsby";
import {ImageDataLike} from "gatsby-plugin-image";

export interface ReferenceNode {
  frontmatter: {
    name: string,
    order: number,
    profileImage: ImageDataLike,
    profileImageAlt: string,
  },
  body: any
}

export interface ReferencesQuery {
  allFile: {
    nodes: [{
      id: string,
      childMdx: ReferenceNode
    }]
  }
}

export default function getReferences() : ReferencesQuery {
  return useStaticQuery(graphql`
  query {
    allFile(
      filter: {relativeDirectory: {eq: "references"}, extension: {eq: "mdx"}}
      sort: {fields: childrenMdx___frontmatter___order, order: ASC}
    ) {
      nodes {
        id
        childMdx {
          frontmatter {
            name
            order
            profileImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            profileImageAlt 
          }
          body
        }
      }
    }
  }
`);
}